_cruxUtils._cruxLocale = {
	"crm.criteria.secondaryfield.valid.check " : "Introduzca el valor coincidente para {0}",
	"crm.label.field":"Campo",//no i18n
"crm.label.value":"Valor",//no i18n
	"sentiment.criteria.wrongcriteria":"El valor de criterios no puede ser superior a {0}",//no i18n
"crm.mb.field.common.splc":"No se permiten caracteres especiales. Especifique un valor v\u00e1lido.",//no i18n
	"crm.label.field.plural":"campos",//no i18n
	"crm.label.in.minutes":"{0} (en minutos)",//no i18n
	"crm.security.roles.list":"Lista de roles",//no i18n
"crm.security.roles.lookup.info":"Seleccione un rol de la lista.",//no i18n
"crm.territory.addterritory":"Agregar territorio",//no i18n
"crm.title.edit.territory":"Editar territorio",//no i18n
"crm.territory.title.assign.territories":"Asignar territorios",//no i18n
	"crm.label.context.help":"Ayuda",//no i18n
	"crm.label.from":"Desde",//no i18n
"crm.label.to":"Para",//no i18n
	"workflow.option.webhookFailure.fromDate":"Desde la fecha",//no i18n
"workflow.option.webhookFailure.toDate":"Hasta la fecha",//no i18n
"crm.custom.field.less.than.equalto":"{0} debe ser inferior o igual a {1}.",//no i18n
	"crm.template.listview.search.no.results":"No se han encontrado resultados",//No I18n
	"crm.wf.usage.date.criteria.error.msg":"La fecha inicial no puede ser posterior a la fecha de finalizaci\u00f3n.",//no i18n
	"crm.label.tag.new":"Nueva etiqueta",//No I18n
	"crm.label.enter.tag":"Introducir etiquetas",//No I18n
	"crux.comboBox.max.limit":"No puede seleccionar m\u00e1s de {0} {1}.",//NO I18n
	"Administrator":"Administrador",//No I18n
	"Standard":"Est\u00e1ndar",//No I18n
	"crm.button.add":"Agregar",//NO I18n
	"crm.label.users":"Usuarios", //NO I18n
  "crm.workflow.alert.roles":"Roles", //NO I18n
  "crm.security.groups":"Grupos", //NO I18n
	"crm.label.available" : "Disponible", //NO I18n
	"crm.label.assign.manually" : "Asignar", //NO I18n
	"crm.globalsearch.option.all": "Todo", //NO I18n
	"webform.status.Active":"Activo", //NO I18n
	"Inactive":"Inactivo", //NO I18n
  "Confirmed":"Confirmado", //NO I18n
  "crm.user.component.unconfirmed":"Sin confirmar",//no i18n
  "DeletedUser":"Eliminado", //NO I18n
  "crm.feed.group.admin":"Admin", //NO I18n
  "crm.ln.lable.current":"Actual", //NO I18n
	"crm.label.selected": "Seleccionado",//NO I18n
	"crm.auditlog.user": "Usuario", //NO I18n
	"cob.role": "Rol", //NO I18n
	"zoho.email": "Correo electr\u00f3nico", //NO I18n
	"Profile": "Perfil", //NO I18n
	"crm.security.group.users.empty": "No se han encontrado usuarios.", //NO I18n
	"crm.label.picklist.none": "Ninguno", //NO I18n
	"crm.usrpop.non.selected" : "Usuarios seleccionados",//NO I18n
	"crm.label.notSelected" : "No seleccionado",//NO I18n
	"AM" : "AM",//NO I18n
	"Call" : "Llamada",//NO I18n
	"crm.phoneNo.Link.Title" : "Llamar con Skype",//NO I18n
	"crm.button.cancel" : "Cancelar",//NO I18n
	"crm.button.save" : "Guardar",//NO I18n
	"crm.no.data.found" : "No se encontraron datos.",//NO I18n
	"crm.zti.label.user": "Nombre de usuario", //NO I18n
	"crm.label.no.options.found" : "No se encontraron opciones.",//No I18n
	"crm.globalsearch.search.title" : "Buscar",//No I18n
	"None" : "Ninguno",//No I18n
	"crm.label.criteria.pattern" : "Patr\u00f3n de criterios",//No I18n
	"crm.label.edit.criteria.pattern" : "Editar patr\u00f3n",//No I18n
	"criteria.error.alert.brackets.count.mismatch" : "Los par\u00e9ntesis del patr\u00f3n no coinciden.",//No I18n
	"criteria.error.alert.brackets.invalid" : "Par\u00e9ntesis no v\u00e1lidos englobando el/los operador(es) de la condici\u00f3n.",//No I18n
	"crm.criteria.number.notmatch.check" : "Compruebe el patr\u00f3n en {0}.",//No I18n
	"criteria.error.alert.other.params" : "Contenido no v\u00e1lido en este patr\u00f3n.", //No I18n
	"crm.label.search.for.users": "Buscar usuarios", //NO I18n
	"criteria.error.alert.andor.rowcount.mismatch" : "El patr\u00f3n de criterios no coincide con las condiciones que ha seleccionado.", //No I18n
	"criteria.error.alert.critnum.rowcount.mismatch" : "El patr\u00f3n de criterios no coincide con las condiciones que ha seleccionado.", //No I18n
	"and" : "y", //No I18n
	"or" : "o", //No I18n
	"crm.label.or" : "O", //No I18n
	"crm.label.and" : "Y", //No I18n
	"crm.criteria.fieldlabel.valid.check" : "Introduzca una etiqueta de campo v\u00e1lida para la fila {0}.", //No I18n
	"crm.criteria.condition.valid.check" : "Especifique una condici\u00f3n v\u00e1lida para {0}.", //No I18n
	"crm.field.valid.check" : "Introduzca un {0} v\u00e1lido.", //No I18n
	"crm.custom.field.less.than.to" : "El intervalo de <i>De</i> no puede ser superior al del intervalo <i>A</i>.", //No I18n
	"crm.alert.label.savepattern" : "Guarde el patr\u00f3n antes de cambiar de criterios.",//No I18n
	"crm.criteria.max.rowcnt.exceeds" : "No puede agregar criterios adicionales.",//No I18n
	"is" : "es",//No I18n
	"isn\'t" : "no est\u00e1",//No I18n
	"contains" : "contiene",//No I18n
	"doesn\'t contain" : "no contiene",//No I18n
	"starts with" : "empieza por",//No I18n
	"ends with" : "termina por",//No I18n
	"is empty" : "est\u00e1 vac\u00edo",//No I18n
	"is not empty" : "no est\u00e1 vac\u00edo",//No I18n
	"is before" : "est\u00e1 antes de",//No I18n
	"is after" : "est\u00e1 despu\u00e9s de",//No I18n
	"between" : "entre",//No I18n
	"not between" : "no entre",//No I18n
	"Today" : "Hoy",//No I18n
	"Tommorow" : "Ma\u00f1ana",//No I18n
	"Tommorow Onwards" : "Comenzando ma\u00f1ana",//No I18n
	"Yesterday" : "Ayer",//No I18n
	"Till Yesterday" : "Hasta ayer",//No I18n
	"Last Month" : "Mes pasado",//No I18n
	"Current Month" : "Mes actual", //No I18n
	"Next Month" : "Siguiente mes", //No I18n
	"Last Week" : "Semana pasada", //No I18n
	"Current Week" : "Semana actual", //No I18n
	"Next Week" : "Siguiente semana", //No I18n
	"Age in Days" : "Edad en d\u00edas", //No I18n
	"Due in Days" : "Vence en d\u00edas", //No I18n
	"Scheduled" : "Programado", //No I18n
	"Attended Dialled" : "Se ha marcado Atendido", //No I18n
	"Unattended Dialled" : "Se ha marcado No atendido", //No I18n
	"Overdue" : "Vencido", //No I18n
	"Cancelled" : "Cancelado", //No I18n
	"Received" : "Recibido", //No I18n
	"Missed" : "Perdido", //No I18n
	"crm.alert.character.not.allowed" : "{0} no se permite", //No I18n
	"crm.condition.in.last" : "en los \u00faltimos", //No I18n
	"crm.zinvoice.dueIn" : "vence el", //No I18n
	"on" : "Activado",//No I18n
	"before" : "antes",//No I18n
	"crm.label.general.small.after" : "despu\u00e9s",//No I18n
	"crm.thisweek" : "Esta semana",//No I18n
	"crm.label.this.month" : "Este mes",//No I18n
	"crm.thisyear" : "Este a\u00f1o",//No I18n
	"crm.source.user.and.system" : "Usuario y sistema",//No I18n
	"crm.source.user.or.system" : "Usuario o sistema",//No I18n
	"crm.label.system2" : "Sistema",//No I18n
	"crm.source.user.only" : "Solo por usuario",//No I18n
	"crm.source.system.only" : "Solo por sistema",//No I18n
	"crm.condition.till.today" : "Hasta hoy",//No I18n
	"game.month.left" : "1 mes",//No I18n
	"game.months.left" : "{0} meses",//No I18n
	"crm.condition.last.30.days" : "En los \u00faltimos 30 d\u00edas",//No I18n
	"crm.condition.last.60.days" : "En los \u00faltimos 60 d\u00edas",//No I18n
	"crm.condition.last.90.days" : "En los \u00faltimos 90 d\u00edas",//No I18n
	"crm.label.filter.typehere" : "Escribir aqu\u00ed", //No I18N
	"crm.filter.is.not" : "no es", //No I18n
	"crm.condition.until.now" : "Hasta ahora",//No I18n
	"crm.filter.email.isblocked" : "est\u00e1 bloqueado",//No I18n
	"crm.filter.email.isnotblocked" : "no est\u00e1 bloqueado",//No I18n
	"crm.label.no.results.match" : "No coincide ning\u00fan resultado",//No I18n
	"crm.label.select.user" : "Haga clic para seleccionar usuarios.", //No I18n
	"current.logged.in.user": "Usuario conectado", //NO I18n
	"current.logged.in.user.definition": "El usuario que inicia la acci\u00f3n de grabaci\u00f3n.", //NO i18n
	"crm.security.group": "Grupo", //NO I18n
	"crm.security.role": "Rol", //NO I18n
	"Date" : "Fecha",//No I18n
	"crm.field.valid.decimal.check2" : "Las posiciones decimales para el campo <i>{0}</i> deben ser inferiores o iguales a {1}.",//No I18n
	"crm.field.empty.check" : "{0} no puede estar vac\u00edo.",//No I18n
	"crm.label.add.note": "Agregar nota", //NO I18n
	"crm.label.simply.by": "por", //NO I18n
	"crm.general.addnote": "Agregar una nota", //NO I18n
	"crm.general.addtitle": "Agregar un t\u00edtulo", //NO I18n
	"crm.label.attach.file": "Adjuntar archivo", //NO I18N
	"crm.button.mass.delete": "Eliminar", //NO I18N
	"crm.warning.delete.record": "\u00bfEst\u00e1 seguro de que desea mover \"{0}\" a la papelera de reciclaje?", //NO I18N
	"crm.label.yes": "S\u00ed", //NO I18N
	"crm.note.view.previous": "Ver notas anteriores", //NO I18N
  "of": "de", //NO I18N
	"crm.label.notes": "Notas", //NO I18N
	"crm.note.recent.first": "Recientes Primera", //NO I18N
	"crm.note.recent.last": "Recientes \u00daltima", //NO I18N
	"crm.territory.label.only": "S\u00f3lo {0}", //no i18n
	"crm.select" : "Seleccionar",//No I18n
	"crm.button.apply.filter" : "Aplicar filtro",//No I18n
	"crm.alert.maximum.text.values.contains" : "No puede introducir m\u00e1s de {0} valores para este campo.",//No I18n
	"PM" : "PM",//No I18n
	"Jan" : "Ene",//No I18n
	"Feb" : "Feb",//No I18n
	"Mar" : "Mar",//No I18n
	"Apr" : "Abr",//No I18n
	"Jun" : "Jun",//No I18n
	"Jul" : "Jul",//No I18n
	"Aug" : "Ago",//No I18n
	"Sep" : "Sep",//No I18n
	"Oct" : "Oct",//No I18n
	"Nov" : "Nov",//No I18n
	"Dec" : "Dic",//No I18n
	"crm.mb.newversion.msg4":"De acuerdo, lo entiendo.", //no i18n
	"crm.label.More" :"M\u00e1s", //no i18n

	"crm.label.unmapped.stages":"Sin contabilizar", //no i18n
	"crm.wf.summary.label.ShowInstantActions" : "Mostrar m\u00e1s",//No I18n
	 "crm.wf.summary.label.HideInstantActions" : "Mostrar menos",//No I18n

	 	 //filter related keys-start
	"crm.inv.mail.time.two.days.subject":"{0} - {1}",//no i18n
	"crm.lead.prediction.tooltip.convert":"PROBABILIDAD",//no i18n
	"crm.lead.prediction.tooltip.score":"PUNTUAR",//no i18n
	"Planned":"Planificado",//no i18n
	"Invited":"Invitado",//no i18n
	"Sent":"Enviado",//no i18n
	"Received":"Recibido",//no i18n
	"Opened":"Abierto",//no i18n
	"Responded":"Respondido",//no i18n
	"Bounced":"Devuelto",//no i18n
	"Opted\ Out":"Excluido",//no i18n
	"crm.filter.label.with.open":"Con {0} abierto",//no i18n
	"crm.filter.label.without.open":"Sin abrir {0}",//no i18n
	"crm.filter.label.without.any":"Sin ning\u00fan {0}",//no i18n
	"crm.filter.label.with.module":"Con {0}",//no i18n
	"crm.filter.label.activity.due":"Vencimiento de {0}",//no i18n
	"crm.filter.label.activity.done":"{0} Listo",//no i18n
	"Notes":"Notas",//no i18n
	"crm.filter.label.notes.added":"Notas agregadas",//no i18n
	"crm.label.filter.email.status":"\u00daltimo estado de correo electr\u00f3nico",//no i18n
	"crm.label.filter.email.clicked":"con clics",//no i18n
	"crm.label.filter.email.responded":"respondido", //no i18n
	"crm.label.filter.email.info":"Filtre los registros seg\u00fan el estado m\u00e1s reciente de sus correos electr\u00f3nicos enviados / recibidos.",//no i18n
	"crm.filter.label.sent":"enviados",//no i18n
	"crm.filter.label.not.sent":"no enviados",//no i18n
	"crm.filter.label.opened":"abiertos",//no i18n
	"crm.filter.label.not.opened":"no abiertos",//no i18n
	"crm.filter.label.received":"recibidos",//no i18n
	"crm.filter.label.not.received":"no recibidos",//no i18n
	"crm.filter.label.bounced":"devueltos",//no i18n
	"crm.filter.label.opened.not.replied":"abierto y no respondido", //no i18n
	"crm.filter.label.any":"Cualquiera de los anteriores",//no i18n
	"crm.zia.config.potential.amount":"Cantidad de {0}",//no i18n
	"Quote\ Stage":"Fase de {0}",//no i18n
	"crm.module.owner":"Propietario de {0}",//no i18n
	"Potential\ Closing\ Date":"Fecha de cierre de {0}",//no i18n
	"crm.lead.prediction.likely.convert":"Con probabilidad de conversi\u00f3n",//no i18n
	"crm.lead.prediction.convert.high":"Alta",//no i18n
	"crm.lead.prediction.convert.medium":"Media",//no i18n
	"crm.lead.prediction.convert.low":"Baja",//no i18n
	"crm.predictions.feature.label":"Predicci\u00f3n",//no i18n
	"crm.intelligence.prediction.likelywin":"Con probabilidad de ganar",//no i18n
	"crm.intelligence.prediction.likelylose":"Con probabilidad de perder",//no i18n
	"crm.intelligence.prediction.halfchance":"Probabilidad 50:50",//no i18n
	"crm.intelligence.prediction.score":"Puntuaci\u00f3n de predicci\u00f3n",//no i18n
	"crm.lead.prediction.recent.score":"Puntuaci\u00f3n de predicci\u00f3n reciente",//no i18n
	"crm.intelligence.prediction.lastconv":"\u00daltimas 3 conversaciones",//no i18n
	"crm.intelligence.prediction.recordsfocus":"Registros en los que centrarse",//no i18n
	"crm.intelligence.prediction.slowmoving":"Movimiento lento",//no i18n
	"crm.intelligence.prediction.trend.down":"\u00daltimamente con tendencia hacia abajo",//no i18n
	"crm.label.touched.records":"Registros modificados",//no i18n
	"crm.label.untouched.records":"Registros no modificados",//no i18n
	"crm.label.record.action":"Acci\u00f3n en registro",//no i18n
	"workflow.rule.view.label.Modified":"Modificado",//no i18n
	"crm.label.not.modified":"No modificado",//no i18n
	"crm.label.related.records.action":"Acci\u00f3n en registros relacionados",//no i18n
	"Done":"Fin",//no i18n
	"crm.label.not.done":"No realizado",//no i18n
	"sentiment.model":"Sentimiento de correo electr\u00f3nico",//no i18n
	"sentiment.criteria.count":"Recuento",//no i18n
	"sentiment.criteria.percentage":"Porcentaje",//no i18n
	"sentiment.criteria.lastmail":"Para el \u00faltimo correo electr\u00f3nico",//no i18n
	"Chats":"Chats",//no i18n
	"Attended":"Atendido",//no i18n
	"crm.lead.prediction.popup.text":"Cuando la probabilidad de conversi\u00f3n es {0}, la puntuaci\u00f3n de predicci\u00f3n debe estar entre {1}.",//no i18n
	"crm.lead.prediction.popup.final":"Cambie el filtro en consecuencia e int\u00e9ntelo nuevamente.",//no i18n
	"crm.custom.field.less.than.to1":"El rango de origen no puede ser mayor que el rango de destino.",//no i18n
	"Last\ Activity\ Date":"Fecha de \u00faltima actividad",//no i18n
	"crm.label.vendor.name":"Nombre de {0}",//no i18n
	"hours":"horas",//no i18n
	"days":"d\u00edas",//no i18n
	"weeks":"semanas",//no i18n
	"months":"meses",//no i18n
	"years":"a\u00f1os",//no i18n
	"crm.label.general.small.after":"despu\u00e9s",//no i18n
	"Last\ Week":"Semana pasada",//no i18n
	"Last\ Month":"Mes pasado",//no i18n
	"crm.module.name":"Nombre de {0}",//no i18n
	"Campaign":"Campa\u00f1a",//no i18n
	"Tasks":"Tareas",//no i18n
	"Calls":"Llamadas",//no i18n
	"Events":"Eventos",//no i18n
	"sentiment.criteria.wrongcriteria":"El valor de criterios no puede ser superior a {0}",//no i18n
	"crm.chosen.minimum.input.text":"Introduzca {0} o m\u00e1s caracteres",//no i18n
	"crm.intelligence.prediction.trendup":"En aumento",//no i18n
	"crm.intelligence.prediction.trenddown":"En disminuci\u00f3n",//no i18n
	"crm.zia.prediction.notrend":"No trend",//no i18n
	"crm.zia.prediction.likelihood":"Likelihood Score",//no i18n
	"Completed":"Completado" ,//no i18n
	"crm.label.success":"Con \u00e9xito" ,//no i18n
	"crm.label.Failure":"Error" ,//no i18n
	"Both":"Ambos" ,//no i18n
	"crm.condition.cannot.empty":"La condici\u00f3n no puede estar vac\u00eda.",//no i18n
	"crm.condition.last.30.days":"En los \u00faltimos 30 d\u00edas",//no i18n
	"crm.condition.last.60.days":"En los \u00faltimos 60 d\u00edas",//no i18n
	"crm.condition.last.90.days":"En los \u00faltimos 90 d\u00edas",//no i18n
	"crm.sentiment.Positive":"Positivo",//no i18n
	"crm.sentiment.Negative":"Negativo",//no i18n
	"sentiment.positiveandnegative":"Positivo y negativo",//no i18n
	"sentiment.positiveornegative":"Positivo o negativo",//no i18n
	"sentiment.positiveonly":"Solo positivo",//no i18n
	"sentiment.negativeonly":"Solo negativo",//no i18n
	"crm.sentiment.Neutral":"Neutro",//no i18n
	"crm.filters.select.campaign.type":"Seleccionar tipo {0}",//no i18n
	"crm.filters.select.campaign.status":"Seleccionar estado {0}",//no i18n
	"campaign.Member" : "Miembro",//no i18n
	"Service":"Servicio",//no i18n
	"Activities":"Actividades",//no i18n
	"crm.livedesk.pot.nextdays":"Pr\u00f3ximos {0} d\u00edas",//no i18n
	"Today\ +\ Overdue":"Hoy + vencidas",//no i18n
	"crm.source.user.and.system":"Usuario y sistema",//no i18n
	"crm.source.user.or.system":"Usuario o sistema",//no i18n
	"User":"Usuario",//no i18n
	"crm.source.user.only":"Solo por usuario",//no i18n
	"crm.source.system.only":"Solo por sistema",//no i18n
	"Scheduled":"Programado",//no i18n
	"Attended\ Dialled":"Se ha marcado Atendido",//no i18n
	"Unattended\ Dialled":"Se ha marcado No atendido",//no i18n
	"Cancelled":"Cancelado",//no i18n
	"crm.filter.email.isblocked":"est\u00e1 bloqueado",//no i18n
	"crm.filter.email.isnotblocked":"no est\u00e1 bloqueado",//no i18n
	"condition.till.now":"Hasta ahora",//no i18n
	"crm.recurring.no.months":"{0} meses",//no i18n
	"crm.lead.prediction.tooltip":"Con probabilidad de conversi\u00f3n - Intervalo de puntuaci\u00f3n",//no i18n
	"crm.website.activity":"Actividad en sitio web",//no i18n
	"crm.label.By":"Por",//no i18n
	"crm.chosen.searching.text":"Buscando...",//no i18n
	"crm.label.memberstatus.is":"y el estado del miembro es",//no i18n
	"crm.events.duration":"Duraci\u00f3n",//no i18n
	"crm.title.clear.name":"Borrar",//no i18n
	"crm.label.status.is":"y el estado es",//no i18n
	"zia.last3.help":"La conversaci\u00f3n incluye llamadas, tareas, {0}, correo electr\u00f3nico recibido, notas, visitas, comentarios en redes sociales, solicitudes de soporte t\u00e9cnico de Desk.",//no i18n
	"crm.label.tag.related.to":"relacionado con",//no i18n

	//filter related keys-End
	"crm.label.account.created" : "Se crear\u00e1 el nuevo {0}.",//No I18n
	"crm.krp.no.records.found" : "No se han encontrado {0}",//No I18n
	"crm.module.new" : "Nuevo {0}",//No I18n
	"crm.label.view" : "Ver",//No I18n
	"crm.nsocial.customers" : "Clientes",//No I18n
	"crm.nsocial.open.potential" : "{0} abierto",//No I18n
	"crm.nsocial.lead.contact" : "{0}/{1}",//No i18n
	"Others" : "Otros",//No i18n
	"crm.field.length.check" : "El valor de {0} supera la longitud m\u00e1xima.", //No I18n
	"crm.lower.now":"ahora",//no i18n
	"crm.time.min.ago":"Hace {0} minutos",//no i18n
	"crm.time.mins.ago":"Hace {0} minutos",//no i18n
	"crm.time.hr.ago":"Hace {0} hora",//no i18n
	"crm.time.hrs.ago":"Hace {0} horas",//no i18n
	"AllUsers": "Todos los usuarios" ,//no i18n
	"crm.label.search":"Buscar",//no i18n
	"crm.api.filterby":"Filtrar por",//no i18n
	"crm.customview.nofields.found":"--No hay campos coincidentes--",//no i18n
	"crm.setup.system.ziarecommendation":"Recomendaci\u00f3n",//no i18n
	"crm.filter.label.all.products":"Todo los {0}",//no i18n
	"crm.filter.label.select.products":"{0} seleccionado",//no i18n
	"crm.reviewprocess.smart.filter":"Estado de registro del proceso de revisi\u00f3n",//no i18n
	"crm.dashboard.sharing.empty.value.alert":"Elija un valor.",//no i18n
	"crm.segmentation.segment.score":"Puntuaci\u00f3n de segmento",//no i18n
	"crm.filter.label.in":"basado en",//no i18n
	"crm.filter.label.and.purchase.in":"y es probable que compre en",//no i18n
	"crm.filter.label.last.purchased":"y ha adquirido recientemente",//no i18n
	"crm.filter.label.a.day":"un d\u00eda",//no i18n
	"crm.filter.label.a.week":"una semana",//no i18n
	"crm.filter.label.a.month":"un mes",//no i18n
	"crm.cal.custom":"Personalizada",//no i18n
	"crm.mb.field.common.empt":"El valor no puede estar vac\u00edo.",//no i18n
	"crm.chosen.error.loading.text":"No hemos podido cargar los resultados",//no i18n
	"crm.filter.label.firstbuy":"Es la primera vez",//no i18n
	"crm.filter.label.cwbab":"Dependiente",//no i18n
	"crm.filter.label.fbt":"Agrupaci\u00f3n de componentes",//no i18n
	"crm.filter.label.rebuy":"Repetir",//no i18n
	"crm.filter.label.nextbuy":"Secuencia",//no i18n
	"crm.mxnlookup.select" : "Asignar {0}",//No I18n
	"crm.lookup.chooserecord":"Elegir {0}",//no i18n
	"crm.record.selected":"Seleccionado {0}",//no i18n
	"crm.module.empty.message" : "No se encontr\u00f3 ning\u00fan {0}",//No I18n
	"crm.mxnlookup.selected" : "Asignado {0}",//No I18n
	"crm.segmentation.recency" : "Frescura", //No I18n
	"crm.segmentation.frequency" : "Frecuencia", //No I18n
	"crm.segmentation.monetary" : "Monetario", //No I18n
	"crm.smartfilter.related.module.msg" : "No puede seleccionar m\u00e1s de 3 m\u00f3dulos relacionados.", //No I18n
	"crm.smartfilter.related.module.msg1" : "(Por ejemplo: Correo electr\u00f3nico, Actividades, Notas)", //No I18n
	"crm.smartfilter.related.module.msg2" : "La duraci\u00f3n no puede estar vac\u00eda", //No I18n
	"crm.label.timeZone": "Zona horaria", //NO I18n
	"crm.label.insufficient.privileges": "No tiene privilegios suficientes para realizar esta operaci\u00f3n. Consulte al administrador.", //NO I18n
	"crm.filter.header.secton.system": "Filtros definidos por el sistema", //NO I18N
	"crm.filter.header.secton.fields": "Filtrar por campos", //NO I18N
	"crm.createfield.calcinfo.new" : "Este campo act\u00faa como calculadora para las expresiones que especifique.</br> <b>Por ejemplo, 20+20</b> producir\u00e1 <b>40</b> autom\u00e1ticamente",//No i18n
	"crm.lable.read.only" : "Campo de solo lectura",//No i18n
	"crm.column.sort.asc" : "Asc",//No I18n
	"crm.column.sort.desc" : "Desc",//No i18n
	"crm.column.unsort" : "Desordenar",//No I18n
	"custmr.prtl.notes.shr.to.custmr": "Compartir con cliente", //NO I18N
	"crm.label.edited": "Edited", //NO I18N
	"crm.label.edited.on": "Edited on", //NO I18N
	"crm.message.limit.exceed": "Solo se permiten {0} caracteres  para {1}.", //NO I18N
	"custmr.prtl.notes.shrd.with.custmr": "Compartido con el cliente", //NO I18N
	"crm.label.creator.noPermission" : "Permiso denegado", //NO I18N
	"crm.security.error" : "No tiene permisos suficientes para realizar esta operaci\u00f3n. P\u00f3ngase en contacto con su administrador.", //NO I18N
	"crm.button.ok" : "Aceptar",   //NO I18N
	"crm.role.already.selected" : "Este rol ya est\u00e1 seleccionado", //no i18n
	"crm.user.deleted": "EL USUARIO SE HA ELIMINADO",  //NO I18N
	"crm.account.closed": "ESTA CUENTA SE HA CERRADO",  //NO I18N
	"crm.start.chat": "Iniciar chat",  //NO I18N
	"crm.start.call": "Iniciar una llamada",  //NO I18N
	"crm.recipient.invalid.email" : "Se han encontrado correos electr\u00f3nicos no v\u00e1lidos.", //NO I18N
	"crm.recipient.add.recipient" : "Agregar destinatario adicional", //NO I18N
	"crm.start.video.call":" Inicia una videollamada",  //NO I18N //ignorei18n_start

	"Score":"Puntuaci\u00f3n",
	"Positive Score":"Puntuaci\u00f3n positiva",
	"Negative Score":"Puntuaci\u00f3n negativa",
	"Touch Point Score":"Puntuaci\u00f3n de Touch Point",
	"Positive Touch Point Score":"Puntuaci\u00f3n positiva de Touch Point",
	"Negative Touch Point Score":"Puntuaci\u00f3n negativa de Touch Point",
	"crm.label.scoring.rules":"Reglas de puntuaci\u00f3n",
	"crm.label.type.minutes": "Escriba aqu\u00ed en minutos", //NO I18N

	"is\ OPEN":"est\u00e1 ABIERTO",//no i18n
	"is\ WON":"est\u00e1 GANADO",//no i18n
	"is\ LOST":"est\u00e1 PERDIDO",//no i18n
	"crm.potential.all.open":"Todas las etapas abiertas",//no i18n
	"crm.potential.all.won":"Todas las etapas ganadas cerradas",//no i18n

	"crm.potential.all.lost":"Todas las etapas perdidas cerradas",//no i18n
	"crm.campaign.member.status" : "Mitgliederstatus",//no i18n
	"crm.dashboard.select.type" : "{0} w\u00e4hlen",//no i18n
	"crm.campaign.service.status":"Servicestatus",//no i18n

	"crm.label.addColumn":"Agregar columna",//no i18n
	"crm.button.clear.filter":"Cerrar filtro",//no i18n
	"crm.button.show.filter":"Mostrar filtro",//no i18n
	"crm.las.error.user.maxlimit":"Solo puede seleccionar un m\u00e1ximo de 20 usuarios.",//no i18n
	"crm.las.error.picklist.maxlimit":"Solo puede seleccionar un m\u00e1ximo de 20 opciones.",//no i18n

	"crm.fileuploader.message.responseerror": "Error al cargar", //NO I18N
	"crm.storage.create.error":"No se pueden crear nuevos registros porque ha alcanzado su l\u00edmite m\u00e1ximo de almacenamiento de datos.",//no i18n
	"crm.storage.create.error.client":"No se pueden crear nuevos registros porque su administrador ha alcanzado el l\u00edmite m\u00e1ximo de almacenamiento. Escriba a {0} para resolver este problema.",//no i18n
	"crm.storage.avail.info":"({0} restante de {1})",//no i18n
	"crm.storage.error.key.manage":"Administre su almacenamiento de datos",//no i18n
	"crm.workflow.alert.additional.recipients" : "Destinatarios adicionales", //NO I18N
	"crm.workflow.alert.type.otherEmails" : "Utilice comas para separar varias direcciones de correo electr\u00f3nico.", //NO I18N
	"crm.related.contact.account" : "{0} relacionado con {1}",//No I18n
	"crm.allcontact.show" : "Todos los {0}",//No I18n
	"crm.button.mass.show" : "Mostrar",//No I18n
	"crm.msg.custom.view.not.replied" : "Mensajes no respondidos", //NO I18N
	"crm.msg.custom.view.replied" : "Mensaje respondido",//NO I18N
	"crm.workflow.select.recipients" : "Destinatarios", //NO I18N
	"crm.custom.module.no.profile.selected.alert1":"Seleccione al menos un perfil.",//NO I18N
	"crm.auto.enrich.remove.default.profile" : "Lo sentimos, pero no puede eliminar un perfil predeterminado.",//NO I18N
	"crm.inv.label.add.emails" : "Agregar correos electr\u00f3nicos", //NO I18N
	"crm.prediction.analytics.filter.year":"A\u00f1o pasado",//no i18n
	"Previous\ FY":"AF anterior",//no i18n
	"Current\ FY":"AF actual",//no i18n
	"Next\ FY":"Pr\u00f3ximo AF",//no i18n
	"Current\ FQ":"TF actual",//no i18n
	"Next\ FQ":"Pr\u00f3ximo TF",//no i18n
	"Previous\ FQ":"TF anterior",//no i18n
	"crm.picklist.sample.text":"Texto de ejemplo",//no i18n
	"crm.more.colors":"M\u00e1s colores",//no i18n
	"crm.button.back.alone":"Atr\u00e1s",//no i18n
	"crm.field.label.email":"Correo electr\u00f3nico",//no i18n
"crm.zia.nba.feature.label":"Siguiente mejor acci\u00f3n",//no i18n
"Meeting":"Reuni\u00f3n",//no i18n
"Tomorrow":"Ma\u00f1ana",//no i18n
"crm.gdpr.notavailable.field":"No disponible",//no i18n
	"crm.setup.system.ziasimilarity":"Recomendaci\u00f3n de similitud",//no i18n
	"crm.gdpr.notavailable.field":"No disponible",//no i18n
	"crm.filter.label.all.products":"Todo los {0}",//NO I18N
	'crm.zia.nbx.filter.due' : "Due", //NO I18N
	"abm.segment": "Segment", // NO I18N
	"abm.segment.names": "Segment Names", // NO I18N
	"abm.abm.segment.name": "ABM Segment Name", // NO I18N
	"abm.segmentation.techniques": "ABM Segmentation Techniques", // NO I18N
	"abm.filter.by.abm.fields": "Filter By ABM Fields", // NO I18N
	"abm.rfm": "RFM", // NO I18N
	"abm.firmographics": "Firmographics", // NO I18N
	"crux.custom.field.greater.than.equalto":"{0} debe ser superior o igual a {1}.",
	"crux.users.selected.plural" : "{0} usuarios seleccionados.",
	"crux.user.selected.singular" :"{0} usuario seleccionado.",
	"crux.criteria.empty.secondaryfield.module" : "No se encontró ningún campo {0} coincidente en el módulo {1}.",
	"crux.criteria.empty.secondaryfield" : "No hay ningún campo {0} disponible para comparación, escriba un valor para comparar.",
	"crux.logged.in.role.definition" : "El rol de usuario que inicia las acciones de registro.",
	"zia.similarity.smartfilter.score":"Puntuaci\u00f3n de similitud",//no i18n
	"zia.similarity.smartfilter.records":"{0} similar de",//no i18n
	"zia.similarity.smartfilter.records.search":"Mostrar {0} similar de",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"No puede seleccionar m\u00e1s de {0} {1}",//no i18n
	"crux.custom.field.greater.than.equalto":"{0} debe ser superior o igual a {1}.",
	"crux.users.selected.plural" : "{0} usuarios seleccionados.",
	"crux.user.selected.singular" :"{0} usuario seleccionado.",
	"crux.criteria.empty.secondaryfield.module" : "No se encontró ningún campo {0} coincidente en el módulo {1}.",
	"crux.criteria.empty.secondaryfield" : "No hay ningún campo {0} disponible para comparación, escriba un valor para comparar.",
	"crux.logged.in.role.definition" : "El rol de usuario que inicia las acciones de registro.",
	"crux.max.limit.unselect" : "No puede deseleccionar más de {0} {1}.", //NO I18N
	"crux.existing.tag" : "\u201c{0}\u201d ya esta seleccionado" //NO I18N

}
